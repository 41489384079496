import React from 'react'
import mobHomepage from './mobhomepage.svg'
import carousel1 from './carousel1.jpg'
import carousel2 from './carousel2.jpg'
import carousel3 from './carousel3.jpg'
import carousel_web_1 from './carousel_web_1.mp4'
import carousel_web_2 from './carousel_web_2.mp4'
import carousel_web_3 from './carousel_web_3.mp4'
import motion from 'framer-motion'
import divider from './divider.png'


import { useState, useEffect } from 'react';
import Footer from './Footer'

export default function Home() {
  
const [width, setWidth] = useState(window.innerWidth);
function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        
    }

}, []);

//THINGS THAT SOLVE ISSUE OF CAROUSEL NOT WORKING UNTIL FIRST CLICK
useEffect(() => {
  const carouselInterval = setInterval(() => {
    // Manually trigger the next slide transition
    // Note: This is necessary for Carousel to start autoplaying
    const nextSlideEvent = new Event('click', { bubbles: true });
    document.querySelector('.carousel-control-next').dispatchEvent(nextSlideEvent);
  }, 6000);

  return () => {
    clearInterval(carouselInterval);
  };
}, []);

const isMobile = width <= 768;

//   window.onbeforeunload = function () {
//     window.scrollTo(0, 0);
//   }   
 
//  window.onload(function(){
//   mainNav();
//   window.scroll(mainNav());
// });

//   function mainNav() {
//       var top = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
//       if (top > 40) $('.navbar-fixed-top').stop().animate({
//           "opacity": '1',
//           "top": '0'
//       });
//       else $('.navbar-fixed-top').stop().animate({
//           "top": '-70',
//           "opacity": '0'
//       });

// }
useEffect(()=>{
const image = document.querySelectorAll('.image-wrapper');
const options={
  root:null,
  rootMargin:'0px',
  
}
// const callbacks=(entries)=>{
//   entries.forEach(entry => {
//     if (entry.isIntersecting) {
//         entry.target.classList.add('image-animation');
//        return; // if we added the class, exit the function
//     }
//     entry.target.classList.remove('image-animation');

// });
// }

  
// let observer = new IntersectionObserver(callbacks, options);
// image.forEach(element=>{
//   observer.observe(element);
// })
},[]);
const PlayVideo=((videoNum)=>{
  let vid = document.getElementsByClassName("img-fluid")[videoNum];
  vid.play();

});


window.onscroll = function() {hideNavbar()};
function hideNavbar(){
const navBar = document.querySelector('.navbar');
if(!isMobile){
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    navBar.style.visibility = 'hidden';
}else{
  navBar.style.visibility = 'visible';

  }
  }
}
// function scrollFunction() {
//   const logo = document.querySelector('.navbar-brand');
//   if(isMobile){
//     if(document.body.scrollTop<window.screen.height || document.documentElement.scrollTop<window.screen.height){
//       document.getElementById('DiamondType').innerHTML = "EARRINGS";
//     }
//     if(document.documentElement.scrollTop>window.screen.height || document.body.scrollTop>window.screen.height){
//       document.getElementById('DiamondType').innerHTML = "BRACELET";
//     }
//     if(document.documentElement.scrollTop>window.screen.height+200 || document.body.scrollTop>window.screen.height+200){
//       document.getElementById('DiamondType').innerHTML = "RING";
    
//     }
//     if(document.documentElement.scrollTop>window.screen.height+400 || document.body.scrollTop>window.screen.height+400){
//       document.getElementById('DiamondType').innerHTML = "KADA";
    
//     }
//     if(document.documentElement.scrollTop>window.screen.height+600 || document.body.scrollTop>window.screen.height+600){
//       document.getElementById('DiamondType').innerHTML = "PENDANT";
    
//     }
//   }
//   else{
//     if(document.body.scrollTop<window.screen.height || document.documentElement.scrollTop<window.screen.height){
//       document.getElementById('DiamondType').innerHTML = "EARRINGS";
//     }
//     if(document.documentElement.scrollTop>window.screen.height || document.body.scrollTop>window.screen.height){
//       document.getElementById('DiamondType').innerHTML = "BRACELET";
//     }
//     if(document.documentElement.scrollTop>window.screen.height*1.5 || document.body.scrollTop>window.screen.height*1.5){
//       document.getElementById('DiamondType').innerHTML = "RING";
    
//     }
//     if(document.documentElement.scrollTop>window.screen.height*2 || document.body.scrollTop>window.screen.height*2){
//       document.getElementById('DiamondType').innerHTML = "KADA";
    
//     }
//     if(document.documentElement.scrollTop>window.screen.height*2.5 || document.body.scrollTop>window.screen.height*2.5){
//       document.getElementById('DiamondType').innerHTML = "PENDANT";
    
//     }
//   }
  
//   if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
//         document.getElementById("navbar").style.top = "0";
//         setTimeout(()=>{
//         logo.style.visibility = "visible";
//         logo.style.top = "2vh";
//       },300);
      
//     } else {
//       if(isMobile){
//         document.getElementById("navbar").style.top = "-200px";
//       }
//       else{
//         document.getElementById("navbar").style.top = "-100px";
//       }
      
//       logo.style.visibility = "hidden";
//       logo.style.top = "2px";
//     }
// }

// window.addEventListener('transitionstart',()=>{
//   let id=document.getElementsByClassName('carousel-item active')[0].getElementsByClassName('img-fluid')[0].id;
//   document.getElementById(parseInt(id)+1===4?1:parseInt(id)+1).play();
// })



if(isMobile){
  return ( 
  <div>  
  <div id="carouselVideoExample"
  className="carousel slide carousel-fade"
  data-bs-ride="carousel"
  data-bs-interval = '6000'>
  <div className="carousel-indicators">
    <button
      type="button"
      data-bs-target="#carouselVideoExample"
      data-bs-slide-to="0"
      className="active"
      aria-current="true"
      aria-label="01"
      // onClick={()=>PlayVideo(0)}
    ></button>
    <button
      type="button"
      data-bs-target="#carouselVideoExample"
      data-bs-slide-to="1"
      aria-label="02"
      // onClick={()=>PlayVideo(1)}
    ></button>
    <button
      type="button"
      data-bs-target="#carouselVideoExample"
      data-bs-slide-to="2"
      aria-label="03"
      // onClick={()=>PlayVideo(2)}
    ></button>
  </div>

  <div className="carousel-inner">
    <div className="carousel-item active">
      <div style={{position:"absolute",width:"100vw",height:'calc(100vh - 150px)',backgroundColor:'rgba(0, 0, 0, 0.6)'}}>
          <div className='carousel-font'>
            <img src={divider} style={{width:"50vw"}}/>
            <h1 style={{color:"white",width:"100vw",textAlign:"center", fontFamily:"Garamond", fontSize:"60px"}}>MAGNIFICIENT<br/>FLORET</h1>
              <p style={{color:"white",width:"100vw",textAlign:"center", fontSize:"20px"}}>Discover a world of enchantment with Magnificent Floret Jewellery, where nature's delicate blossoms are transformed into stunning pieces of wearable art. Our exquisite collection celebrates the timeless elegance and ethereal beauty of flowers, capturing their essence in intricate designs that will leave you breathless. </p>
              <img src={divider} style={{width:"50vw"}}/>
          </div>
      </div>
      <img id= '1' src={carousel1} style={{height:'calc(100vh - 150px)',width:"100vw",objectFit:"cover"}}/>   
    </div>
    <div className="carousel-item">
      <div style={{position:"absolute",width:"100vw",height:'calc(100vh - 150px)',backgroundColor:'rgba(0, 0, 0, 0.6)'}}>
            <div className='carousel-font'>
              <img src={divider} style={{width:"50vw",objectFit:"cover"}}/>
              <h1 style={{color:"white",width:"100vw",textAlign:"center",fontFamily:"Garamond", fontSize:"60px"}}>CHARISMATIC<br/>FLORALS</h1>
                <p style={{color:"white",width:"100vw",textAlign:"center", fontSize:"20px"}}>Like dewdrops glistening in the morning sun, our Petal Drop Earrings add a touch of elegance to any ensemble. Each earring features a cascading series of intricately crafted petals, adorned with shimmering diamonds or lustrous pearls.</p>
                <img src={divider} style={{width:"50vw"}}/>
            </div>
        </div>
        <img id= '2' src={carousel2} style={{height:'calc(100vh - 150px)',objectFit:"cover"}}/>
    </div>
    <div className="carousel-item">
      <div style={{position:"absolute",width:"100vw",height:'calc(100vh - 150px)',backgroundColor:'rgba(0, 0, 0, 0.6)'}}>
          <div className='carousel-font'>
            <img src={divider} style={{width:"50vw"}}/>
            <h1 style={{color:"white",width:"100vw",textAlign:"center",fontFamily:"Garamond", fontSize:"60px"}}>STRAP OF <br/>PERIWRINKLE</h1>
              <p style={{color:"white",width:"100vw",textAlign:"center", fontSize:"20px"}}>Step into a world of refined beauty with the Strap of Periwinkle Bracelet, a true embodiment of grace and sophistication. Inspired by the ethereal charm of the periwinkle flower, this exquisite piece of jewelry captures the essence of delicate petals and transforms it into a wearable work of art.</p>
              <img src={divider} style={{width:"50vw"}}/>
          </div>
      </div>
        <img id= '3' src={carousel3} style={{height:'calc(100vh - 150px)',objectFit:"cover"}}/>
    </div>

    {/* <div className="carousel-item">
      <video className="img-fluid" id='2' style={{height:'calc(100vh - 150px)',objectFit:"cover"}} autoPlay muted playsInline>
        <source src={carousel2} type="video/mp4" />
      </video>
      
    </div>

    <div className="carousel-item">
      <video className="img-fluid" id='3' style={{height:"calc(100vh - 150px)",objectFit:"cover"}} autoPlay muted playsInline>
        <source
          src={carousel3}
          type="video/mp4"
        />
      </video>
      
    </div> */}
  </div>
  <button
    className="carousel-control-prev"
    type="button"
    data-bs-target="#carouselVideoExample"
    data-bs-slide="prev"
  >
    <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next"
    type="button"
    data-bs-target="#carouselVideoExample"
    data-bs-slide="next"
  >
    <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
  
</div>
        
        <Footer/>
    </div>
  )  
}
else{
  return (
    
    <div >
     
      {/* {isLoading&&<Spinner/>} */}
      {/* <img src={mobHomepage} alt='HOSB' style={{width:"100%", height:"100vh"}}></img> */}
      {/* <img onClick={handleGifClick} src={require('./down_arrow.gif')} title='arrow' style={{width:"148px", height:"148px"}}/>        */}
  <div id="carouselVideoExample"
  className="carousel carousel-dark slide"
  data-bs-ride="carousel"
  data-bs-interval = '4000'
  >
  <div className="carousel-indicators">
    <button
      type="button"
      data-bs-target="#carouselVideoExample"
      data-bs-slide-to="0"
      className="active"
      aria-current="true"
      aria-label="Slide 1"
      // onClick={()=>PlayVideo(0)}
    ></button>
    <button
      type="button"
      data-bs-target="#carouselVideoExample"
      data-bs-slide-to="1"
      aria-label="Slide 2"
      // onClick={()=>PlayVideo(1)}
    ></button>
    <button
      type="button"
      data-bs-target="#carouselVideoExample"
      data-bs-slide-to="2"
      aria-label="Slide 3"
      // onClick={()=>PlayVideo(2)}
    ></button>
  </div>

<div className="carousel-inner">
  
    <div className="carousel-item active">
      <video className="img-fluid" style={{height:'calc(100vh - 105px)',width:"100vw",objectFit:"fill"}} id='1'autoPlay muted preload='auto'>
        <source src={carousel_web_1} type="video/mp4" />
      </video>
      
    </div>

    <div className="carousel-item">
      <video className="img-fluid" id='2' style={{height:'calc(100vh - 105px)',width:"100vw",objectFit:"fill"}} autoPlay muted preload='auto'>
        <source src={carousel_web_2} type="video/mp4" onEnded={()=>{document.getElementById("#img-fluid")}}/>
      </video>
      
    </div>

    <div className="carousel-item">
      <video className="img-fluid" id='3' style={{height:"calc(100vh - 105px)",width:"100vw",objectFit:'fill'}} autoPlay muted preload='auto'>
        <source
          src={carousel_web_3}
          type="video/mp4"
        />
      </video>
      
    </div>
</div>
  
  
  {/* <button
    className="carousel-control-prev"
    type="button"
    data-bs-target="#carouselVideoExample"
    data-bs-slide="prev"
    onClick={()=>{let id=document.getElementsByClassName('carousel-item active')[0].getElementsByClassName('img-fluid')[0].id;
    console.log(parseInt(id)-1==0?3:parseInt(id)-1);
    document.getElementById(parseInt(id)-1==0?3:parseInt(id)-1).play();
  
  }}
  >
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next"
    type="button"
    data-bs-target="#carouselVideoExample"
    data-bs-slide="next"
    onClick={()=>{let id=document.getElementsByClassName('carousel-item active')[0].getElementsByClassName('img-fluid')[0].id;
    document.getElementById(parseInt(id)+1==4?1:parseInt(id)+1).play();
  
  }}

  >
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button> */}
</div>
    {/* <div style={{backgroundColor:"#fde8e8"}}>
      <h1 style={{textAlign:"center",color:"#1d3353",letterSpacing:"4px"}}>Customers</h1>
      <h2>
       <Number/>
      </h2>
    </div> */}
    {/* <div style={{height: "100%", display: "flex"}}>
      <div style={{flex:"base", height:"100%", overflow:"visible",width:"60%"}}>
          <div className="image-wrapper" style={{width:"70%",height:"70%", marginLeft:"20px"}}>
            <img className="image" src={earrings} alt='HOSB' style={{width:"100%", height:"100%"}}></img>
          </div>
          <div className="image-wrapper" style={{marginTop:"50px",width:"70%",height:"70%",marginLeft:"auto"}}>
            <img className="image" src={kada} alt='HOSB' style={{width:"100%", height:"100%"}}></img>
          </div>
          <div className="image-wrapper" style={{marginTop:"50px",width:"70%",height:"70%",marginLeft:"20px"}}>
            <img className="image" src={ring} alt='HOSB' style={{width:"100%", height:"100%"}}></img>
          </div>

          <div className="image-wrapper" style={{marginTop:"50px",width:"70%",height:"70%",marginLeft:"auto"}}>
            <img  className="image" src={bracelet} alt='HOSB' style={{width:"100%", height:"100%"}}></img>
          </div>
          <div className="image-wrapper" style={{marginTop:"50px",width:"70%",height:"70%",marginLeft:"20px"}}>
            <img className="image" src={pendant} alt='HOSB' style={{width:"100%", height:"100%"}}></img>
          </div>
      </div>
      <div style={{width:"5%"}}></div>
      <div style={{height:"100%",width:"30%",position:"sticky",top:150}}>
        <div style={{fontFamily:"Garamond, serif",fontSize:"4vw",color:"#6c757d"}}><p>Delivering best to</p></div>
        <div style={{fontFamily:"Garamond, serif",fontSize:"4vw",color:"#6c757d"}}><p>our customer through</p></div>
          <label id = "DiamondType" style={{fontFamily:"Georgia",fontSize:"5vw",color:"black"}}>EARRINGS</label>
      </div>
    </div> */}
        {/* <div className='mb-2 mb-md-4'></div> */}
        
        <Footer/>
    </div>
  )  
}
  
}
