
import './App.css';
import React from 'react'
import Navbar from './components/Navbar'
import Home from './components/Home'
import About from './components/About'
import { Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import CheckCertificate from './components/CheckCertificate';
import ConnectWithUs from './components/ConnectWithUs';
import Spinner from './components/Spinner';
import { useState, useEffect } from 'react';

function App() {
  
  const navigate = useNavigate();
  const [ spinner, setSpinner ] = useState(true);

  // It will be executed before rendering

  useEffect(() => {
      setTimeout(() => setSpinner(false), 3000);
  }, []);

  // [] means like componentDidMount

  
  return (
    spinner?<Spinner/>:
      <div className="App">
          { spinner && <Spinner/>}
          <Navbar navigate={navigate}/>
          
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/About" element={<About />} />
              <Route path="/Certificate" element={<CheckCertificate />} />
              <Route path="/Connect" element={<ConnectWithUs />} />
            </Routes>
          
          
    </div>
  );
}

export default App;
