import React from 'react'

export default function ConnectWithUs() {
  return (
    <div>
        <div className="box">
            <a href="#popup-box">
                Click to Open Popup Box !
            </a>
        </div>
        <div id="popup-box" className="modal">
            <div className="content">
                <h1 style={{color: "green"}}>
                    Hello GeeksForGeeks !
                </h1>
                <b>
                    <p>Never Give Up !</p>
                </b>
                <a href="#"
                    className="box-close">
                    ×
                </a>
            </div>
        </div>
    </div>
  )
}
