import React from 'react';
import 'reactjs-popup/dist/index.css';
import { useState, useEffect } from 'react';
import {storage} from './firebase';
import {ref, listAll, getDownloadURL} from "firebase/storage";
import search from './search.svg';
import { Document, Page,pdfjs } from 'react-pdf';

export default function UploadImg() {

//const[imageUpload, setImageUpload] = useState(null);
const[searchValue, setSearchValue] = useState(null);

const [imageList, setImageList] = useState([]);
// const [imageName, setImageName] = useState([]);
useEffect(()=>{
    const imageListRef = ref(storage,"images/");
    //const logo = document.querySelector('.navbar-brand');
    document.getElementById("navbar").style.top = 0;
    // logo.style.visibility = "visible";
    // logo.style.top = "2vh";
    listAll(imageListRef).then((response)=>{
        response.items.forEach((item)=>{
            //setImageName((prev)=>[...prev, item.name.split(".")[0]])
            getDownloadURL(item).then((url)=>{
                setImageList((prev) => [...prev, url]);
            })
        })
    
    })
},[])

var pattern = /^[0-9]\d{7}$/gi;
pdfjs.GlobalWorkerOptions.workerSrc = 
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
     
const [numPages, setNumPages] = useState(null);
      const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
const checkSearch = (()=>{
    
   
    if(searchValue===null || searchValue === ""){
        
        
        return <div style={{color:"#1d3353",padding:"90px 0px 106px 0px", fontFamily:"Arial"}}>Looking for Certificate? Enter Report Number</div>
    
    }
    
    else if(pattern.test(searchValue) === true && imageList.filter(url=>url.includes(searchValue)).length!=0){
        
        return <div style={{color:"#1d3353",padding:"90px 10px 90px 10px", fontFamily:"Arial"}}>
            {
                imageList.filter(url=>url.includes(searchValue)).map((url,index)=>{ 
                    console.log(url);
                    return <div>
                                <div key={index} className="mx-auto ">
                                    
                                        {/* <div>
                                            
                                            <Document
                                                file={url}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                                >
                                                <Page pageNumber={pageNumber} />
                                            </Document>
                                        </div> */}
                                        <img src={url} style={{width:"90vw"}}></img>
                                       
                                                
                                        {/* <table className="table table-bordered">
                                            <thead>
                                                
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>3</td>
                                                    <td colSpan="8" className="table">Larry the Bird</td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td scope="row">3</td>
                                                    <td colSpan="2" className="table">Larry the Bird</td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td scope="row">3</td>
                                                    <td colSpan="2" className="table">Larry the Bird</td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table> */}
                                </div>
                            </div>
                           
                            
                })
            }
        </div>
        
        
            }
            else if(pattern.test(searchValue)!== true){

                return <div style={{color:"#1d3353",padding:"90px 0px 90px 0px", fontFamily:"Arial"}}><p>Please enter correct Report Number</p></div>
            }
})



    // const uploadImage  = ()=>{
    //     if(imageUpload ==null) return;
    //         const imageRef = ref(storage,`images/${imageUpload.name}`);
    //         uploadBytes(imageRef,imageUpload).then((snapshot)=>{
    //             getDownloadURL(snapshot.ref).then((url)=>{
    //                 setImageList((prev)=>[...prev, url])
    //             })
                
    //         })

    // }
    
   const handleSearch=(()=>{
    setSearchValue(document.getElementById("searchBox").value);
    checkSearch();
   })
   
const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  //document.getElementById("navbar").style.top = "0";

  if(isMobile){
    return (
		<div style={{padding:"50px 0px 0px 0px"}}>
			<div className="container-fluid justify-content-center">
                <div style={{color:"#1d3353", padding:"0px 0px 50px 0px"}}><h1>VERIFY YOUR REPORT</h1>
                </div>

                <div className="row justify-content-center">

                    <div className='col-8' style={{alignContent:"center"}}>
                        <input type="text" id = "searchBox" className="form-control" placeholder="Enter your Report No."style={{borderRadius:"22px 22px 22px 22px "}}/>
                    </div>
                    <div className='col-2'>
                    <button className="btn"  onClick={handleSearch}><img src = {search} alt='Search'/></button>
                    </div>
                </div>   
            </div>  
            {checkSearch()}

            <div className='footer' style={{padding:"10px 10px 10px 10px",textAlign:"left",  fontFamily:"Arial",backgroundColor:"#fde8e8",color:"#1d3353", fontSize:"10px"}}>

              
                    <ul>
                    <li>The layout of the online certificate may look different from the hard copy in your possession</li>
                    <li>Please, it is very important to always check that the certificate number and the comments are the same.</li>
                    <li>All certificates are available online for 3 years</li>
                    </ul>
                    
            </div>
            
            
                    
            
            </div>
     
	)
  }else{
    return (
        <div style={{padding:"150px 0px 0px 0px"}}>
			<div className="container-fluid justify-content-center">
                <div style={{color:"#1d3353", padding:"0px 0px 50px 0px"}}><h1>VERIFY YOUR REPORT</h1>
                </div>

                <div className="row justify-content-center">

                    <div className='col-8' style={{alignContent:"center"}}>
                        <input type="text" id = "searchBox" className="form-control" placeholder="Enter your Report No."style={{borderRadius:"22px 22px 22px 22px "}}/>
                    </div>
                    <div className='col-2'>
                    <button className="btn"  onClick={handleSearch}><img src = {search} alt='Search'/></button>
                    </div>
                </div>   
            </div>  
            {checkSearch()}

            <div className='footer' style={{padding:"10px 10px 10px 10px",textAlign:"left",  fontFamily:"Arial",backgroundColor:"#fde8e8",color:"#1d3353", fontSize:"10px"}}>

              
                    <ul>
                    <li>The layout of the online certificate may look different from the hard copy in your possession</li>
                    <li>Please, it is very important to always check that the certificate number and the comments are the same.</li>
                    <li>All certificates are available online for 3 years</li>
                    </ul>
                    
            </div>
            
            
                    
            
            </div>
	)
  }

}


