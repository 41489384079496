

import React from 'react'
import { useState,useEffect } from 'react';

export default function Navbar({navigate}) {
    const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
  
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
          
      }
  }, []);
    const isMobile = width <= 768;
    const handleHomeClick = () => navigate('/');
    const handleAboutClick = () => navigate('/About');
    const handleCertificateClick = () => navigate('/Certificate');
    const handleImgClick=()=> navigate('/');
    const hideLogo=()=>{
        //const logo = document.querySelector('.navbar-brand');
        // if(logo.style.visibility==="hidden"){
        //     setTimeout(()=>{ logo.style.visibility = "visible"
        // },200);
           
        // }else{
            
        // logo.style.visibility = "hidden";
        // }

    }
    
    if(isMobile){
        return (
            <div style={{position:"relative"}}>
            <nav id = "navbar" className="navbar navbar-expand-lg border-bottom border-dark shadow p-3 " >
            <div style={{display:"flex",alignItems:"center"}}>
                <div className="ms-auto">
                    <h1 style={{fontFamily:["Daragie-Regular"],fontSize:"50px",textAlign:"center",color:"#1d3353",letterSpacing:"4px",lineHeight:"38px"}}>HOSB</h1>  
                    <h5 style={{fontWeight:"lighter",fontFamily:"sans-serif",fontSize:"12px",textAlign:"center",color:"#1d3353",letterSpacing:"4px"}}>HOUSE OF SB</h5> 
                </div>
                <i  className="navbar-toggler material-icons ms-auto"  onClick={hideLogo} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation" style={{borderColor:"white", float:"right"}}>menu</i>
                    
            </div>
             
             <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                
                    <ul className="navbar-nav ">
                    <hr className="divider"/>
                        <li className="nav-item">
                            
                            <a className="nav-link" href="/" style={{fontFamily:["Dubiel"]}} onClick={() => handleHomeClick()}>Home</a>
                        
                        </li>
                        
                        <li className="nav-item">
                            
                            <a className="nav-link" href="/About" style={{fontFamily:["Dubiel"]}} onClick={() => handleAboutClick()}>About Us</a>
                        </li>
        
                        <li className="nav-item">
                            <a className="nav-link" href="/Certificate" style={{fontFamily:["Dubiel"],marginRight:"2vw"}} onClick={() => handleCertificateClick()}>Check Certificate</a>
                        </li>
                  
                    </ul>
        
                </div>
        </nav>
        {/* <img className="navbar-brand"src = {logo} alt='Logo' style={{width:"5em",height:"5em",borderBottom:"0px"}}  onClick={handleImgClick}/> */}
        
            </div>
          )
    }else{
        return (
            
            <nav id = "navbar" className="navbar navbar-expand-lg p-3">
             
             <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                <div>
                    <h1 style={{fontFamily:["Daragie-Regular"],fontSize:"50px",textAlign:"center",color:"#1d3353",letterSpacing:"4px",lineHeight:"38px"}}>HOSB</h1>  
                    <h5 style={{fontWeight:"lighter",fontFamily:"sans-serif",fontSize:"12px",textAlign:"center",color:"#1d3353",letterSpacing:"4px"}}>HOUSE OF SB</h5> 
                </div>
                    <ul className="navbar-nav ms-auto ">
                    <hr className="divider"/>
                    
                    
                        <li className="nav-item">
                            
                            <a className="nav-link" href="/" style={{fontFamily:["Dubiel"]}} onClick={() => handleHomeClick()}>Home</a>
                        
                        </li>
                        
                        <li className="nav-item">
                            
                            <a className="nav-link" href="/About" style={{fontFamily:["Dubiel"]}} onClick={() => handleAboutClick()}>About Us</a>
                        </li>
        
                        <li className="nav-item">
                            <a className="nav-link" href="/Certificate" style={{fontFamily:["Dubiel"],marginRight:"2vw"}} onClick={() => handleCertificateClick()}>Check Certificate</a>
                        </li>
                  
                    </ul>
        
                </div>
        </nav>        
          
          )
    }
  
}

