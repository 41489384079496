import {React,useState, useEffect} from 'react'
import {db} from './firebase';
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';




export default function Footer() {
  const [name, setName] = useState("");
  const [mobNum, setmobName] = useState("");

  const [width, setWidth] = useState(window.innerWidth);
function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        
    }

}, []);
const isMobile = width <= 768;  
// const saveDetails=(event)=>{
//   event.preventDefault();
//   const elementArray = [...event.target.elements];
//   const formData = elementArray.reduce((accumulator,currentValue)=>{
//       if(currentValue.id){
//           accumulator[currentValue.id]= currentValue.value;
//       }
//       return accumulator
//   },{});
const navigate = useNavigate();
  const saveDetails = async (e) => {
    e.preventDefault();  
   
    try {
        const docRef = await addDoc(collection(db, "inputDetails"), {
          mobNum: mobNum,
          name:name    
        });
        console.log("Document written with ID: ", docRef.id);
        
        
        // navigate('/#');
      } catch (e) {
        console.error("Error adding document: ", e);
      }
  // db.collection("contactDetails").add(formData);


}


  return (
    <footer id="footer" className="footer-1">
<div className="main-footer widgets-dark typo-light">
<div className="container">
<div className="row">
  
{/* <div className="col-xs-12 col-sm-6 col-md-3">
<div className="widget subscribe no-box">
<h5 className="widget-title">Our Story<span></span></h5>
<p>Delivering best to our customers is our goal</p>
</div>
</div> */}

<div className="col-xs-12 col-sm-6 col-md-3">
<div className="widget no-box">
<h4 className="widget-title">Quick Links<span></span></h4>
<ul className="thumbnail-widget">
<li>
<div className="thumb-content"><a style={{textDecorationLine:"none",fontSize:isMobile?"3vw":"2vh"}} href="#popup-box">Connect with us</a><div id="popup-box" className="modal">
            <div className="content">
                <h1 style={{color: "#1d3353"}}>
                    Connect with us
                </h1>
                <form onSubmit={saveDetails}>
                  <br/>
                  <div className="form-group">
                    <label style={{color:"#1d3353",fontWeight:"bold",padding:"0px 0px 10px 0px"}}>Full Name</label>
                    <input type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter name" onChange={(e)=>setName(e.target.value)}/>
                  </div>
                  <br/>
                  <div className="form-group">
                    <label  style={{color:"#1d3353",fontWeight:"bold",padding:"0px 0px 10px 0px"}}>Mobile Number</label>
                    <input type="phone" className="form-control" id="mobile" placeholder="Enter Number" pattern="[0-9]{10}" onChange={(e)=>setmobName(e.target.value)}/>
                  </div>
                  <br/>
                  <small id="emailHelp" className="form-text text-muted">We'll never share your details with anyone else.</small>
                  <p></p>
                  <a href="#" type='submit' style={{backgroundColor:"#fde8e8",color:"#1d3353",border:"1px solid #1d3353",padding:"5px 20px 5px 20px",borderRadius:"10px"}} >Submit</a>
                </form>
                
                <a href="#"
                    className="box-close">
                    ×
                </a>
            </div>
        </div></div>	
</li>
<li>
<div className="thumb-content" style={{fontSize:isMobile?"3vw":"2vh"}}><a style={{textDecorationLine:"none"}} href="/Certificate">Check Certificate</a></div>	
</li>
<li>
<div className="thumb-content" style={{fontSize:isMobile?"3vw":"2vh"}}><a style={{textDecorationLine:"none"}} href="/About">About Us</a></div>	
</li>
</ul>
</div>
</div>


<div className="col-xs-12 col-sm-6 col-md-3">

<div className="col-lg-4 col-xs-12  col-xl-12 location">
      <h4 className="widget-title mt-lg-0 mt-sm-4">Contact Us<span></span></h4>
      <p className='contact' style={{fontSize:isMobile?"3vw":"2vh"}}><a href='https://goo.gl/maps/vBHSmCmsU4woAsmK7'><i className="bi bi-geo-alt-fill mr-3"></i>&nbsp;8060, ATM Ring Road, Surat</a></p>
      <p className="contact" style={{fontSize:isMobile?"3vw":"2vh"}}><a href='tel:09377788435'><i className="bi bi-phone mr-3"></i> (+91) 9377788435</a></p>
      <p className="contact" style={{fontSize:isMobile?"3vw":"2vh"}}><a href="mailto:houseofsharmilabajari@gmail.com"><i className="bi bi-envelope mr-3" ></i>  karambhumijewellers@gmail.com</a></p>
      <p className="contact" style={{fontSize:isMobile?"3vw":"2vh"}}><a href="https://www.instagram.com/houseofsb_"><i className="bi bi-instagram mr-3" ></i>  houseofsb_</a></p>
</div>
</div>

</div>
</div>
</div>
  
<div className="footer-copyright">
<div className="container">
<div className="row">
<div className="col-md-12 text-center">
<p>Copyright HOSB © 2022. All rights reserved.</p>
</div>
</div>
</div>
</div>
</footer>
  )
}
