import React, { useEffect, useState } from 'react'
import blank from './sharmila.png'
import Footer from './Footer'
import floral_back from './floral_back.png'

export default function About() {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
  
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
          
      }
  }, []);
  
  const isMobile = width <= 768;
// useEffect(()=>{
//   const logo = document.querySelector('.navbar-brand');
//     // document.getElementById("navbar").style.top = 0;
//     // logo.style.visibility = "visible";
//     // logo.style.top = "2vh";
// })

    
  
if(isMobile){
  return (
    
    <div style={{backgroundImage:`url(${floral_back})`,backgroundPosition:"top",backgroundPositionX:"-200px"}}>
      <div style={{marginBottom:"120px", padding:"10px 25px 10px 25px"}}>
        <h1 style={{margin:"0px 0px 50px 0px",fontFamily:"Montserrat",fontSize:"50px",color:"#1d3353"}}>OUR STORY</h1>
        <p style={{color:"#1d3353", fontFamily:"Baskerville",fontSize:"15px",marginBottom:"80px",textAlign:"start"}}>
        Our journey began with a passion for capturing the essence of precious moments and transforming them into exquisite works of art.
Inspired by the allure of gemstones, the grace of delicate metals, and the timeless appeal of fine jewellary, we set out to create a brand that embodies luxury, sophistication, and individuality.
<br/><br/>Our artisans, with their expert craftsmanship and attention to detail, handcraft each piece with love and dedication. Every gemstone is carefully selected for its exceptional quality and brilliance, ensuring that our jewellary radiates with unparalleled beauty.
We believe that jewellary is more than just an accessory; it is a reflection of one's unique style, personality, and cherished memories. Whether it's a captivating engagement ring, a symbol of eternal love, or a stunning necklace that adds a touch of elegance to any ensemble, our jewellary pieces are designed to make a statement and become heirlooms to be treasured for generations.

        </p>
        <div style={{display:"block",padding:"0px 0px 40px 0px"}}>
          <img className="rounded" src={blank} alt="Sharmila" style={{width:"80vw",height:"40vh",border:"2px solid #1d3353"}}></img>
            
              <p style={{fontFamily:"Georgia",fontSize:"1.5em",color:"#1d3353",padding:"10px 10px 0px 10px"}}>MEET SHARMILA</p>
              <p style={{fontFamily:"Georgia",fontSize:"0.8em",color:"#1d3353"}}>She is the founder of House of SB. Her mission is to deliver the art of customization combined with skilled artisan experience in the form of unique and exquisite diamond jewellary</p>
            
        </div>
       
        
    </div>
    <Footer/>


{/* <div className="row">
  <div className="col-md-4">
   
        <img className="rounded mx-auto d-block border border-dark" src={sharmila} alt="Sharmila" style={{width:"50%"}}></img>
        <div className="caption">
          <p style={{fontFamily:"Baskerville",fontSize:"50px",color:""}}>Sharmila Bajari</p>
          <p style={{fontFamily:"Dubiel"}}>Founder</p>
        </div>
  </div>
  <div className="col-md-4">
   
        <img className="rounded mx-auto d-block border border-dark" src={saloni} alt="Rimisha" style={{width:"50%"}}></img>
        <div className="caption">
          <p style={{fontFamily:"Bulgatti",fontSize:"50px",color:"darkcyan"}}>Rimisha Bajari</p>
          <p style={{fontFamily:"Dubiel"}}>Co-Founder</p>
        </div>
  </div>
  <div className="col-md-4">
    
        <img className="rounded mx-auto d-block border border-dark" src={saloni} alt="Saloni" style={{width:"50%"}}></img>
        <div className="caption">
          <p style={{fontFamily:"Bulgatti",fontSize:"50px",color:"darkcyan"}}>Saloni Bajari</p>
          <p style={{fontFamily:"Dubiel"}}>Co-Founder</p>
        </div>

  </div> */}
     
    </div>
  )
}
else{
  return (
    
    <div >
      <div style={{marginBottom:"120px", padding:"10px 25px 10px 25px"}}>
        <h1 style={{margin:"0px 0px 50px 0px",fontFamily:"Montserrat",fontSize:"50px",color:"#1d3353"}}>OUR STORY</h1>
        <p style={{color:"#1d3353", fontFamily:"Baskerville",fontSize:"15px",marginBottom:"80px",textAlign:"start"}}>
        Our journey began with a passion for capturing the essence of precious moments and transforming them into exquisite works of art.
Inspired by the allure of gemstones, the grace of delicate metals, and the timeless appeal of fine jewellary, we set out to create a brand that embodies luxury, sophistication, and individuality.
<br/><br/>Our artisans, with their expert craftsmanship and attention to detail, handcraft each piece with love and dedication. Every gemstone is carefully selected for its exceptional quality and brilliance, ensuring that our jewellary radiates with unparalleled beauty.
We believe that jewellary is more than just an accessory; it is a reflection of one's unique style, personality, and cherished memories. Whether it's a captivating engagement ring, a symbol of eternal love, or a stunning necklace that adds a touch of elegance to any ensemble, our jewellary pieces are designed to make a statement and become heirlooms to be treasured for generations.

        </p>
        <div style={{display:"block",padding:"0px 0px 40px 0px"}}>
          <img className="rounded" src={blank} alt="Sharmila" style={{width:"50vw",height:"60vh",border:"2px solid #1d3353"}}></img>
            
              <p style={{fontFamily:"Georgia",fontSize:"1.5em",color:"#1d3353",padding:"10px 10px 0px 10px"}}>MEET SHARMILA</p>
              <p style={{fontFamily:"Georgia",fontSize:"0.8em",color:"#1d3353"}}>She is the founder of House of SB. Her mission is to deliver the art of customization combined with skilled artisan experience in the form of unique and exquisite diamond jewellary</p>
            
        </div>
       
        
    </div>
    <Footer/>


{/* <div className="row">
  <div className="col-md-4">
   
        <img className="rounded mx-auto d-block border border-dark" src={sharmila} alt="Sharmila" style={{width:"50%"}}></img>
        <div className="caption">
          <p style={{fontFamily:"Baskerville",fontSize:"50px",color:""}}>Sharmila Bajari</p>
          <p style={{fontFamily:"Dubiel"}}>Founder</p>
        </div>
  </div>
  <div className="col-md-4">
   
        <img className="rounded mx-auto d-block border border-dark" src={saloni} alt="Rimisha" style={{width:"50%"}}></img>
        <div className="caption">
          <p style={{fontFamily:"Bulgatti",fontSize:"50px",color:"darkcyan"}}>Rimisha Bajari</p>
          <p style={{fontFamily:"Dubiel"}}>Co-Founder</p>
        </div>
  </div>
  <div className="col-md-4">
    
        <img className="rounded mx-auto d-block border border-dark" src={saloni} alt="Saloni" style={{width:"50%"}}></img>
        <div className="caption">
          <p style={{fontFamily:"Bulgatti",fontSize:"50px",color:"darkcyan"}}>Saloni Bajari</p>
          <p style={{fontFamily:"Dubiel"}}>Co-Founder</p>
        </div>

  </div> */}
     
    </div>
  )
}
  
}
