// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage';
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDdCYzMPDG9lMNsUcCwXDUa2nb_hvZFff0",
  authDomain: "uploadimage-e7598.firebaseapp.com",
  projectId: "uploadimage-e7598",
  storageBucket: "uploadimage-e7598.appspot.com",
  messagingSenderId: "252895455689",
  appId: "1:252895455689:web:e0e7c69c29e12f2b76a018"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);
export{storage, db}