import React, { useEffect, useState } from 'react'
import loading from "./hosb_loader.mp4"
import logo from './logo.png'
import Loader from './Loader.json'
import Lottie from 'lottie-react'
export default function Spinner() {
  

  const [isLowPowerMode, setIsLowPowerMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    const handleLowPowerModeChange = (event) => {
      setIsLowPowerMode(event.matches);
    };

    mediaQuery.addListener(handleLowPowerModeChange);
    handleLowPowerModeChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleLowPowerModeChange);
    };
  }, []);

  
  return (
  <div className='text-center'>
  <Lottie className="ms-auto"animationData={Loader} style={{height:"calc(100vh - 100px)",width:"100vw",scale:"0.2"}}></Lottie>
  </div>
  
  )
}
